.about-me{
    display: flex;
gap:1rem;
padding:0.2rem;

}
.left{
    display: flex;
    flex:1 1;
    gap: 2rem;
    flex-direction: column;
    color: white;
}
.left>:nth-child(1){
    color:grey;
    font-weight: bold;

}
.left>:nth-child(4){
  text-transform: none;
  text-align: center;
  letter-spacing:2px;
  line-height: 40px;
    
}
.right{
   flex:1;
position: relative;    
}
.right>img{

 position: absolute;
width: 12rem;
height: 12.2rem;
object-fit:cover;
right:8rem;
top:2rem;
bottom:2rem;
left: 5rem;

}
.right>:nth-child(1){
    position: absolute;
    width: 12rem;
    height:16rem;
    border:2px solid rgba(0, 0, 0, 0.733);
    background-color:transparent;
    right:9rem;
    top:-2rem;
    left:5rem;
    border-radius:5px;
}
.right>:nth-child(2){
    position: absolute;
    width: 12rem;
    height:15rem;
    background-color:rgba(25, 26, 25, 0.774) ;
    right:7rem;
    top:0rem;
    left:3rem;
    border-radius:5px;
}
.arrows{
    display: flex;
    position: absolute;
    gap:1rem;
    bottom:-5rem;
    left:-5rem;
}
.arrows>img{
    width:1rem;
    cursor:pointer;
}
@media screen and (max-width: 768px) {
    .about-me{
        flex-direction: column;
    }
    .left>:nth-child(2){
        font-size:large;

    }
    .left>:nth-child(3){

    }
    .right{
display: flex;
flex-direction: column;
align-items: center;
gap:2rem;

    }
    .right>div{
        position: relative;
        display: none;
    }
    .right>img{
        top:0;right:0;position:relative; align-self: center;
    }
    .right>:last-child{
        display:block;
        bottom:0; left:0
    }
}